import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const Footer = props => (
  <footer id="footer">
    <ScrollAnimation animateIn="bounceInUp" animateOnce={true} offset={-10}>
      <section>
        <h2 className="footer-title">Find me on social media</h2>
        <ul className="icons">
          <li>
            <a
              title="link to twitter account"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/gregolls"
              className="icon fa-twitter alt"
            >
              <span className="label">link to twitter account</span>
            </a>
          </li>
          <li>
            <a
              title="link to gitHub account"
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/gfunk1230"
              className="icon fa-github alt"
            >
              <span className="label">link to gitHub account</span>
            </a>
          </li>
          <li>
            <a
              title="link to linkedIn profile"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/greg-peralta-campoverde-1916542a/"
              className="icon fa-linkedin alt"
            >
              <span className="label">link to linkedIn profile</span>
            </a>
          </li>
        </ul>
      </section>
      <p className="copyright">
        Mobile First Advocate | Engineering Leader | UX Web Engineer
      </p>
    </ScrollAnimation>
  </footer>
)

export default Footer
